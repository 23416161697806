<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img class="col-12 header-img" src="@/assets/top-background-section.webp">
      <div class="d-flex flex-column router-view col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
        <router-view></router-view>
      </div>
      <div class="col-12 footer d-flex justify-content-center">
        <img class="quizinc-footer-img" src="@/assets/quizinc-logo.webp">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./assets/vc-logo.png`)
    },
  },
  beforeMount() {
    this.setFavicon();
  }
}
</script>
<style>
.router-view {
  position: absolute;
  height: auto;
  top: 125px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #191919;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quizinc-footer-img {
  position: fixed;
  bottom: 0;
  width: 200px;
}

.header-img {
  position: relative;
  top: -70px;
}
</style>
