import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
    {
        path: '/',
        redirect: '/request-otp'
    },
    {
        path: '/request-otp',
        name: 'RequestOtp',
        component: () => import("@/pages/views/RequestOtp"),
    },
    {
        path: '/submit-otp',
        name: 'SubmitOtp',
        component: () => import("@/pages/views/SubmitOtp"),
    },
    {
        path: '/redeem-code',
        name: 'RedeemCode',
        component: () => import("@/pages/views/RedeemCode"),
    },
    {
        path: '/success',
        name: 'SuccessPage',
        component: () => import("@/pages/views/SuccessPage"),
    },
    {
        path: '/already-subscribed',
        name: 'AlreadySubscribed',
        component: () => import("@/pages/views/AlreadySubscribed"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next)
})

export default router
