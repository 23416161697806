import {createStore} from 'vuex'

export default createStore({
  state: {
    user: undefined,
    isLoading: false,
    msisdn: undefined,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn
    },
  },
  actions: {},
  modules: {}
})
